import React, {useEffect} from 'react';

function loadScript(src, scriptContent) {
    const script = document.createElement('script');

    if (src) {
        script.async = true;
        script.src = src;
    }

    if (scriptContent) {
        script.text = scriptContent;
    }

    document.body.appendChild(script);
}

function SetCookieScripts() {
    const allowCookieURLPath = ['/'];

    /*
    https://www.livongo.com/path*, eg. path healthy-living, should NOT render
    Tatari, Meta, Bing, TikTok, or gtag script attached in
    the body element.

    Meanwhile url such as, https://www.livongo.com/, should contains
    the scripts listed above.
    */
    if (
        typeof window === 'undefined' ||
        !allowCookieURLPath.includes(window.location.pathname)
    ) {
        return null;
    }

    useEffect(() => {
        const loadAllScripts = () => {
            try {
                // Tatari Script
                loadScript(
                    null,
                    `!function(){try{!function(t,i){if(!i.version){window.tatari=i,i.init=function(t,n){var e=function(t,n){i[n]=function(){t.push([n].concat(Array.prototype.slice.call(arguments,0)))}};"track pageview identify".split(" ").forEach(function(t){e(i,t)}),i._i=t,i.config=n,i.pageview()},i.version="1.2.1";var n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://d2hrivdxn8ekm8.cloudfront.net/tag-manager/f0ed0305-a45c-418d-a37d-e44ee713b38a-latest.js";var e=t.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)}}(document,window.tatari||[])}catch(t){console.log(t)}}(); tatari.init('f0ed0305-a45c-418d-a37d-e44ee713b38a');`
                );

                // Meta/Facebook Script
                loadScript(
                    null,
                    `!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${process.env.FACEBOOK_PIXEL_ID});
                fbq('track', 'PageView');`
                );

                // Bing Script
                loadScript(
                    null,
                    `(function(w,d,t,r,u){
                    var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"15338932"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`
                );

                // TikTok Script
                loadScript(
                    null,
                    `!function (w, d, t) {
                    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

                    ttq.load('CCGULRRC77UE82V3Q06G');
                    ttq.page();
                  }(window, document, 'ttq');`
                );

                // Google gtag Script
                loadScript(
                    null,
                    `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.GOOGLE_ADS_TOKEN}');`
                );

                loadScript(
                    'https://www.googletagmanager.com/gtag/js?id=AW-944321597',
                    null
                );

                // Google Conversion Script
                loadScript(
                    null,
                    `function gtag_report_conversion(url) {
                        var callback = function () {
                        if (typeof(url) != 'undefined') {
                            window.location = url;
                        }
                        };
                        gtag('event', 'conversion', {
                            'send_to': '${process.env.GOOGLE_ADS_TOKEN}' + '/yF6wCJi_-e8BEL3opMID',
                            'event_callback': callback
                        });
                        return false;
                    }`
                );
            } catch (error) {
                console.error('Error loading scripts: ', error);
            }
        };

        loadAllScripts();

        // Cleanup on component unmount
        return () => {
            const scripts = document.querySelectorAll('script');
            scripts.forEach(script => {
                if (
                    script.text.includes('tatari') ||
                    script.text.includes('fbq') ||
                    script.text.includes('uetq') ||
                    script.text.includes('ttq') ||
                    script.src.includes('googletagmanager.com')
                ) {
                    document.body.removeChild(script);
                }
            });
        };
    }, []);

    return (
        <>
            <noscript
                dangerouslySetInnerHTML={{
                    __html: `
                        <img
                        height="1"
                        width="1"
                        style="display: none"
                        src="https://www.facebook.com/tr?id=1360340410668285&ev=PageView&noscript=1"
                        />
                            `,
                }}
            />
            <meta
                name="facebook-domain-verification"
                content="s5ra6swggmaqkkp6q53d5dsl7bm1sh"
            />
        </>
    );
}

export default SetCookieScripts;
